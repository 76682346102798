import React, { Component } from 'react'
import Layout from '../../../components/layout';
import { Banner } from '../../../components/banner';
import { navigate } from '@reach/router'
import { Cache } from "aws-amplify";
import { isLoggedIn } from "../../../services/auth";
import { Card, Badge, Container, Row, Col, Alert } from 'reactstrap'
import Query from '../../../containers/netsclick/query';
import { netsclickAppRequestorStatus  } from '../../../services/api';
import { APP_STATUS, COLOR_CONSTANT } from '../../../utils/constants';
import moment from 'moment';

let maxNetsclickApplicationLimit = process.env.NETSCLICK_MAX_APP_SIZE

export default class netsClick extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetched: false,
            requestorsAppList: null,
            displayErrorAlert : false,
            maxNetsclickApplicationLimit : maxNetsclickApplicationLimit

        }

    }
    async componentDidMount() {
        if (!isLoggedIn()) {
            navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        }

    }
    submitNewRequestorApp() {
        navigate('/product/netsclick/requestor/?force=true')
        if (typeof window !== "undefined") {
            window.location.reload()
        }

    }
    gotoOverviewPage(appDetails) {
        localStorage.setItem('ncApp', JSON.stringify(appDetails))
        navigate(`/product/netsclick/overview`)
        if (typeof window !== "undefined") {
            window.location.reload()
        }
    }
    displayError(){
        this.setState({
            displayErrorAlert : true
        })
    }
    async componentWillMount() {
        if (!isLoggedIn()) {
            navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        }
        if (Cache.getItem("ma") != 1) {
            navigate(`/account/mfauth/`, { state: { netsClick: true } })
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        }

        const user = isLoggedIn() ? JSON.parse(window.localStorage.getItem("gatsbyUser")) : false
        let email = ( user && user.userData && user.userData.attributes) ? user.userData.attributes.email : "";

        let requestorStatus = await netsclickAppRequestorStatus(email)
        if(requestorStatus){
            this.setState({
                requestorsAppList: requestorStatus,
                fetched: true
            })
        } else {
            navigate('/product/netsclick/requestor')
        if (typeof window !== "undefined") {
            window.location.reload()
        }
        }
        

    }

    render() {
        let { fetched, requestorsAppList } = this.state
        let netsClickAppSize = requestorsAppList ? requestorsAppList.length : 0
        if (!fetched) {
            return <div class="loading"></div>
        }

        const CardGrid = ({ cards }) => {
            return (
                <Container>
                    <Alert className="centerAligned" color="danger" isOpen={this.state.displayErrorAlert}>  Application not approved.</Alert>
                    <Row>
                        { cards ? cards.map((application, index) => (
                            <Col sm={4} key={index}>
                                <Card
                                    className="topAlignedMedium"
                                    color={COLOR_CONSTANT[application.app_status]}
                                    outline
                                    style={{
                                        width: '16rem',
                                        padding: '15px',
                                        cursor: 'pointer'
                                    }}

                                    onClick={() => {
                                        if (application.app_status == 'A') {
                                            this.gotoOverviewPage(application)
                                        } else {
                                            this.displayError()
                                        }
                                    } }
                                >
                                    <div><u>NETS Click Integration for <b>{application ? application.merchant_name : ''}</b></u></div>
                                    <div className='topAligned'></div>
                                    
                                    <div><small>Date of access granted : { application && application.updated_at ? moment(application.updated_at).format('DD-MM-YYYY'): ''}</small></div>
                                    <div><small>Integration Method : {application ? application.int_method : ''}</small></div>
                                    <div>Status : <Badge color={COLOR_CONSTANT[application.app_status]}>{application ? APP_STATUS[application.app_status] : ''}</Badge> </div>
                                </Card>
                            </Col>
                        )) : ''}
                    </Row>
                </Container>
            );
        };

        return (
            <Layout>
                <Banner title={`NETS Click`} />
                <div className='leftAligned'>
                    <h4 className='topAligned'><b>Welcome to NETS Click developer portal page.</b></h4>
                    <h4 className='topAlignedMedium'><b>Click on below merchant integration to continue</b></h4>
                    <CardGrid cards={requestorsAppList} />
                        {
                           netsClickAppSize < this.state.maxNetsclickApplicationLimit ?  <div className='hyperlink topAlignedMedium' onClick={() => this.submitNewRequestorApp()}><u>Integration for a new merchant? Click here to request for new access</u></div>
                            : ''
                        }
                    </div>
                <Query />
            </Layout>
        )
    }

}
